import React from 'react'

export default function RangeAnnotation() {
	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					position: 'relative',
					width: '100%',
					marginTop: '8px',
				}}
			>
				<div style={{ textAlign: 'center' }}>1</div>
				{[...Array(8)].map((_, index) => (
					<div key={index} style={{ width: '2px', height: '6px', backgroundColor: '#000' }} />
				))}
				<div style={{ textAlign: 'center' }}>10</div>
			</div>
		</>
	)
}
