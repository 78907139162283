import React from 'react'
import { Modal, Accordion } from 'react-bootstrap'

export default function ReadFeedback({ showFeedback, handleCloseFeedback, feedbacks }) {
	return (
		<Modal show={showFeedback} onHide={handleCloseFeedback}>
			<Modal.Header closeButton>
				<Modal.Title>Les tilbakemeldingar</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Accordion>
					{feedbacks?.map((feedback, index) => (
						<div key={index} className="mb-1">
							<Accordion.Item eventKey={index}>
								<Accordion.Header>{feedback.title}</Accordion.Header>
								<Accordion.Body>
									<div>
										<p>{feedback.message}</p>
										<p style={{ fontSize: '0.8em' }}>
											Innsendt av: <em>{feedback.sender}</em>
										</p>
									</div>
								</Accordion.Body>
							</Accordion.Item>
						</div>
					))}
				</Accordion>
			</Modal.Body>
		</Modal>
	)
}
