import React, { useState, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { getAdmins } from '../database'

export default function AdminRoute() {
	const { currentUser } = useAuth()
	const [admins, setAdmins] = useState()
	const [isLoading, setIsLoading] = useState(true)

	async function asyncSetAdmins() {
		setAdmins(await getAdmins())
		setIsLoading(false)
	}

	useEffect(() => {
		asyncSetAdmins()
	}, [])

	if (isLoading) {
		return null
	}

	return currentUser && admins?.includes(currentUser.email) ? <Outlet /> : <Navigate to="/menu" />
}
