import React from 'react'
import { Alert, Button, Modal, Form } from 'react-bootstrap'

export default function EditBurgerPlace({
	showEditBurgerPlace,
	handleCloseEditBurgerPlace,
	selectedEditName,
	setSelectedEditName,
	burgerPlaces,
	editUrl,
	setEditUrl,
	loadingEditBurgerPlace,
	handleSubmitEditBurgerPlace,
	locations,
	checkedEditLocations,
	handleCheckboxChange,
	errorEditBurgerPlace,
}) {
	return (
		<Modal show={showEditBurgerPlace} onHide={handleCloseEditBurgerPlace}>
			<Modal.Header closeButton>
				<Modal.Title>Rediger burgerplass</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="editBurgerPlaceForm.ControlInput1">
						<Form.Label>Burgerplass</Form.Label>
						<Form.Select
							custom="true"
							value={selectedEditName ?? ''}
							onChange={(e) => setSelectedEditName(e.target.value)}
						>
							{burgerPlaces?.map((burgerPlace, index) => (
								<option key={index} value={burgerPlace.id}>
									{burgerPlace.displayName}
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Form.Group className="mb-3" controlId="editBurgerPlaceForm.ControlInput2">
						<Form.Label>Ny link til heimeside</Form.Label>
						<Form.Control
							type="url"
							placeholder="www.burgerplass.no"
							value={editUrl ?? ''}
							onChange={(e) => setEditUrl(e.target.value)}
						/>
					</Form.Group>
					<Form.Group className="mb-3" controlId="editBurgerPlaceForm.ControlInput3">
						<Form.Label>Lokasjonar</Form.Label>
						{locations?.map((location, index) => {
							return (
								<Form.Check
									key={index}
									label={location}
									type="checkbox"
									value={location}
									checked={checkedEditLocations?.includes(location)}
									onChange={handleCheckboxChange}
								/>
							)
						})}
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{errorEditBurgerPlace && <Alert variant="danger">{errorEditBurgerPlace}</Alert>}
				<Button variant="secondary" onClick={handleCloseEditBurgerPlace}>
					Avbryt
				</Button>
				<Button
					disabled={loadingEditBurgerPlace}
					style={{ backgroundColor: 'rgb(33,33,60)' }}
					onClick={handleSubmitEditBurgerPlace}
				>
					Oppdater burgerplass
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
