export function SetRatingFromScore(place, index, places, isSortingScoreBurger, propertyName, burgerPropertyName) {
	let rating = index + 1
	if (index > 0) {
		let prevIndex = index - 1
		while (
			prevIndex >= 0 &&
			(isSortingScoreBurger
				? place?.[burgerPropertyName] === places[prevIndex]?.[burgerPropertyName]
				: place?.[propertyName] === places[prevIndex]?.[propertyName])
		) {
			rating = prevIndex + 1
			prevIndex = prevIndex - 1
		}
	}
	return {
		...place,
		rating,
	}
}
