import React, { useState, useRef, useEffect } from 'react'
import { Card, Button, Container } from 'react-bootstrap'
import {
	setNewBurgerPlace,
	setNewAdmin,
	getAdmins,
	getUsers,
	deleteBurgerPlace,
	editBurgerPlace,
	getBurgerPlacesWithId,
	getLocations,
	getBurgerPlaceLocations,
	getBurgerPlaceUrl,
	updateLocations,
	getFeedback,
	getStatistics,
} from '../database'
import Header from './Header'
import AddAdmin from './Modals/Admin/AddAdmin'
import AddBurgerPlace from './Modals/Admin/AddBurgerPlace'
import AddLocation from './Modals/Admin/AddLocation'
import DeleteBurgerPlace from './Modals/Admin/DeleteBurgerPlace'
import EditBurgerPlace from './Modals/Admin/EditBurgerPlace'
import ReadFeedback from './Modals/Admin/ReadFeedback'
import ReadStatistics from './Modals/Admin/ReadStatistics'

export default function Admin() {
	const displayNameRef = useRef()
	const nameRef = useRef()
	const urlRef = useRef()
	const emailRef = useRef()
	const deleteNameRef = useRef()
	const [newLocation, setNewLocation] = useState(null)
	const [selectedEditName, setSelectedEditName] = useState(null)
	const [editUrl, setEditUrl] = useState(null)
	const [checkedEditLocations, setCheckedEditLocations] = useState([])
	const [nonAdmins, setNonAdmins] = useState()
	const [loadingNonAdmins, setLoadingNonAdmins] = useState(true)
	const [showBurgerPlace, setShowBurgerPlace] = useState(false)
	const [showDeleteBurgerPlace, setShowDeleteBurgerPlace] = useState(false)
	const [showEditBurgerPlace, setShowEditBurgerPlace] = useState(false)
	const [showAdmin, setShowAdmin] = useState(false)
	const [showLocationEdit, setShowLocationEdit] = useState(false)
	const [showFeedback, setShowFeedback] = useState(false)
	const [showStatistics, setShowStatistics] = useState(false)
	const [loadingLocationEdit, setLoadingLocationEdit] = useState(false)
	const [errorLocationEdit, setErrorLocationEdit] = useState(false)
	const [loadingAdmin, setLoadingAdmin] = useState(false)
	const [errorAdmin, setErrorAdmin] = useState(false)
	const [loadingBurgerPlace, setLoadingBurgerPlace] = useState(false)
	const [errorBurgerPlace, setErrorBurgerPlace] = useState(false)
	const [loadingDeleteBurgerPlace, setLoadingDeleteBurgerPlace] = useState(false)
	const [errorDeleteBurgerPlace, setErrorDeleteBurgerPlace] = useState(false)
	const [loadingEditBurgerPlace, setLoadingEditBurgerPlace] = useState(false)
	const [errorEditBurgerPlace, setErrorEditBurgerPlace] = useState(false)
	const [burgerPlaces, setBurgerPlaces] = useState()
	const [loadingBurgerPlaces, setLoadingBurgerPlaces] = useState(true)
	const [locations, setLocations] = useState()
	const [checkedLocations, setCheckedLocations] = useState([])
	const [feedbacks, setFeedbacks] = useState([])
	const [statistics, setStatistics] = useState({})

	async function asyncSetBurgerPlaces() {
		setBurgerPlaces(await getBurgerPlacesWithId())
		setLoadingBurgerPlaces(false)
	}

	async function asyncSetNonAdmins() {
		const admins = await getAdmins()
		const users = await getUsers()
		const nonAdminsData = users.filter((user) => !admins.includes(user))
		setNonAdmins(nonAdminsData)
		setLoadingNonAdmins(false)
	}

	async function asyncSetLocations() {
		setLocations(await getLocations())
	}

	async function asyncSetCheckedEditLocations() {
		setCheckedEditLocations(await getBurgerPlaceLocations(selectedEditName))
	}

	async function asyncSetEditUrl() {
		setEditUrl(await getBurgerPlaceUrl(selectedEditName))
	}

	async function asyncSetFeedbacks() {
		setFeedbacks(await getFeedback())
	}

	async function asyncSetStatistics() {
		setStatistics(await getStatistics())
	}

	useEffect(() => {
		asyncSetNonAdmins()
		asyncSetBurgerPlaces()
	}, [loadingAdmin, loadingBurgerPlace, loadingDeleteBurgerPlace])

	useEffect(() => {
		asyncSetLocations()
	}, [loadingLocationEdit])

	useEffect(() => {
		if (selectedEditName !== null) {
			asyncSetCheckedEditLocations()
			asyncSetEditUrl()
		}
		// eslint-disable-next-line
	}, [selectedEditName])

	async function handleSubmitBurgerPlace(e) {
		e.preventDefault()

		try {
			setErrorBurgerPlace('')
			setLoadingBurgerPlace(true)
			await setNewBurgerPlace(
				nameRef.current.value,
				displayNameRef.current.value,
				urlRef.current.value,
				checkedLocations
			)
			setShowBurgerPlace(false)
		} catch {
			setErrorBurgerPlace('Noko gjekk galt! Kunne ikkje lagra burgerplass')
		}
		setLoadingBurgerPlace(false)
	}

	async function handleSubmitAdmin(e) {
		e.preventDefault()

		try {
			setErrorAdmin('')
			setLoadingAdmin(true)
			await setNewAdmin(emailRef.current.value)
			setShowAdmin(false)
		} catch {
			setErrorAdmin('Noko gjekk galt! Kunne ikkje laga ny admin')
		}
		setLoadingAdmin(false)
	}

	async function handleSubmitLocation(e) {
		e.preventDefault()

		try {
			setErrorLocationEdit('')
			setLoadingLocationEdit(true)
			await updateLocations(newLocation)
			setShowLocationEdit(false)
		} catch {
			setErrorLocationEdit('Noko gjekk galt! Kunne ikkje legga til ny lokasjon')
		}
		setLoadingLocationEdit(false)
	}

	async function handleSubmitDeleteBurgerPlace(e) {
		e.preventDefault()

		try {
			setErrorDeleteBurgerPlace('')
			setLoadingDeleteBurgerPlace(true)
			await deleteBurgerPlace(deleteNameRef.current.value)
			setShowDeleteBurgerPlace(false)
		} catch {
			setErrorDeleteBurgerPlace('Noko gjekk galt! Kunne ikkje sletta burgerplass')
		}
		setLoadingDeleteBurgerPlace(false)
	}

	async function handleSubmitEditBurgerPlace(e) {
		e.preventDefault()

		try {
			setErrorEditBurgerPlace('')
			setLoadingEditBurgerPlace(true)
			await editBurgerPlace(selectedEditName, editUrl, checkedEditLocations)
			setShowEditBurgerPlace(false)
		} catch {
			setErrorEditBurgerPlace('Noko gjekk galt! Kunne ikkje redigera burgerplass')
		}
		setLoadingEditBurgerPlace(false)
	}

	const handleCheckboxChange = (event, setFunction) => {
		const location = event.target.value
		if (event.target.checked) {
			setFunction((prevLocations) => [...prevLocations, location])
		} else {
			setFunction((prevLocations) => prevLocations.filter((loc) => loc !== location))
		}
	}

	const handleCloseBurgerPlace = () => {
		setShowBurgerPlace(false)
		setErrorBurgerPlace('')
	}
	const handleShowBurgerPlace = () => setShowBurgerPlace(true)

	const handleCloseDeleteBurgerPlace = () => {
		setShowDeleteBurgerPlace(false)
		setErrorDeleteBurgerPlace('')
	}
	const handleShowDeleteBurgerPlace = () => setShowDeleteBurgerPlace(true)

	const handleCloseEditBurgerPlace = () => {
		setShowEditBurgerPlace(false)
		setErrorEditBurgerPlace('')
	}
	const handleShowEditBurgerPlace = () => setShowEditBurgerPlace(true)

	const handleCloseAdmin = () => {
		setShowAdmin(false)
		setErrorAdmin('')
	}
	const handleShowAdmin = () => setShowAdmin(true)

	const handleCloseLocationEdit = () => {
		setShowLocationEdit(false)
		setErrorLocationEdit('')
	}
	const handleShowLocationEdit = () => setShowLocationEdit(true)

	const handleCloseFeedback = () => setShowFeedback(false)
	const handleShowFeedback = () => {
		asyncSetFeedbacks()
		setShowFeedback(true)
	}

	const handleCloseStatistics = () => setShowStatistics(false)
	const handleShowStatistics = () => {
		asyncSetStatistics()
		setShowStatistics(true)
	}

	if (loadingNonAdmins || loadingBurgerPlaces) {
		return null
	}

	return (
		<>
			<Header />
			<Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '75vh' }}>
				<div className="w-100" style={{ maxWidth: '400px' }}>
					<Card>
						<Card.Body>
							<h2 className="text-center mb-3">Adminmeny</h2>
							<div className="d-grid gap-3">
								<Button style={{ backgroundColor: 'rgb(33,33,60)' }} onClick={handleShowBurgerPlace}>
									Legg til burgerplass
								</Button>
								<Button
									style={{ backgroundColor: 'rgb(33,33,60)' }}
									onClick={handleShowEditBurgerPlace}
								>
									Rediger burgerplass
								</Button>
								<Button
									style={{ backgroundColor: 'rgb(33,33,60)' }}
									onClick={handleShowDeleteBurgerPlace}
								>
									Slett burgerplass
								</Button>
								<Button style={{ backgroundColor: 'rgb(33,33,60)' }} onClick={handleShowAdmin}>
									Legg til admin
								</Button>
								<Button style={{ backgroundColor: 'rgb(33,33,60)' }} onClick={handleShowLocationEdit}>
									Legg til lokasjon
								</Button>
								<Button style={{ backgroundColor: 'rgb(33,33,60)' }} onClick={handleShowFeedback}>
									Les tilbakemeldingar
								</Button>
								<Button style={{ backgroundColor: 'rgb(33,33,60)' }} onClick={handleShowStatistics}>
									Sjå statistikk
								</Button>
							</div>
						</Card.Body>
					</Card>
				</div>
			</Container>

			<AddBurgerPlace
				showBurgerPlace={showBurgerPlace}
				handleCloseBurgerPlace={handleCloseBurgerPlace}
				displayNameRef={displayNameRef}
				nameRef={nameRef}
				urlRef={urlRef}
				loadingBurgerPlace={loadingBurgerPlace}
				handleSubmitBurgerPlace={handleSubmitBurgerPlace}
				locations={locations}
				handleCheckboxChange={(event) => handleCheckboxChange(event, setCheckedLocations)}
				errorBurgerPlace={errorBurgerPlace}
			/>

			<EditBurgerPlace
				showEditBurgerPlace={showEditBurgerPlace}
				handleCloseEditBurgerPlace={handleCloseEditBurgerPlace}
				selectedEditName={selectedEditName}
				setSelectedEditName={setSelectedEditName}
				burgerPlaces={burgerPlaces}
				editUrl={editUrl}
				setEditUrl={setEditUrl}
				loadingEditBurgerPlace={loadingEditBurgerPlace}
				handleSubmitEditBurgerPlace={handleSubmitEditBurgerPlace}
				locations={locations}
				checkedEditLocations={checkedEditLocations}
				handleCheckboxChange={(event) => handleCheckboxChange(event, setCheckedEditLocations)}
				errorEditBurgerPlace={errorEditBurgerPlace}
			/>

			<DeleteBurgerPlace
				showDeleteBurgerPlace={showDeleteBurgerPlace}
				handleCloseDeleteBurgerPlace={handleCloseDeleteBurgerPlace}
				deleteNameRef={deleteNameRef}
				burgerPlaces={burgerPlaces}
				loadingDeleteBurgerPlace={loadingDeleteBurgerPlace}
				handleSubmitDeleteBurgerPlace={handleSubmitDeleteBurgerPlace}
				errorDeleteBurgerPlace={errorDeleteBurgerPlace}
			/>

			<AddAdmin
				showAdmin={showAdmin}
				handleCloseAdmin={handleCloseAdmin}
				emailRef={emailRef}
				nonAdmins={nonAdmins}
				loadingAdmin={loadingAdmin}
				handleSubmitAdmin={handleSubmitAdmin}
				errorAdmin={errorAdmin}
			/>

			<AddLocation
				showLocationEdit={showLocationEdit}
				handleCloseLocationEdit={handleCloseLocationEdit}
				addLocationRef={newLocation}
				setNewLocation={setNewLocation}
				loadingLocationEdit={loadingLocationEdit}
				handleSubmitLocation={handleSubmitLocation}
				errorLocationEdit={errorLocationEdit}
			/>

			<ReadFeedback showFeedback={showFeedback} handleCloseFeedback={handleCloseFeedback} feedbacks={feedbacks} />

			<ReadStatistics
				showStatistics={showStatistics}
				handleCloseStatistics={handleCloseStatistics}
				statistics={statistics}
			/>
		</>
	)
}
