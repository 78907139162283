import React, { useState, useEffect, useRef } from 'react'
import Header from './Header'
import { Accordion, Container, Button, Form } from 'react-bootstrap'
import {
	getMyBurgers,
	setNewMyBurger,
	getBurgerPlacesWithId,
	updateBurgerPlacePoints,
	editBurgerPlacePoints,
	deleteMyBurger,
	deleteBurgerPlacePoints,
	uploadImageAndGetUrl,
	deleteImage,
} from '../database'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import Assessment from './Assessment'
import DeleteAssessment from './Modals/Assessment/DeleteAssessment'
import EditAssessment from './Modals/Assessment/EditAssessment'
import AddAssessment from './Modals/Assessment/AddAssessment'
import '../styles.css'
import { SetRatingFromScore } from '../helpers'

export default function MyAssessments() {
	const displayNameRef = useRef()
	const meatRef = useRef()
	const bunRef = useRef()
	const extraRef = useRef()
	const friesRef = useRef()
	const dipRef = useRef()
	const commentRef = useRef()
	const [myBurgers, setMyBurgers] = useState()
	const [showNewAssessment, setShowNewAssessment] = useState(false)
	const [loadingNewAssessment, setLoadingNewAssessment] = useState(false)
	const [errorNewAssessment, setErrorNewAssessment] = useState(false)
	const [burgerPlaces, setBurgerPlaces] = useState()
	const [loadingBurgerPlaces, setLoadingBurgerPlaces] = useState(true)
	const [showEdit, setShowEdit] = useState(false)
	const [showDelete, setShowDelete] = useState(false)
	const [assessmentToEdit, setAssessmentToEdit] = useState()
	const [loadingEdit, setLoadingEdit] = useState(false)
	const [errorEdit, setErrorEdit] = useState(false)
	const [assessmentToDelete, setAssessmentToDelete] = useState()
	const [loadingDelete, setLoadingDelete] = useState(false)
	const [errorDelete, setErrorDelete] = useState(false)
	const [newAssessmentImage, setNewAssessmentImage] = useState(null)
	const [editAssessmentImage, setEditAssessmentImage] = useState(null)
	const [isSortingScoreBurger, setIsSortingScoreBurger] = useState(false)
	const { currentUser } = useAuth()
	const navigate = useNavigate()

	async function asyncSetBurgerPlaces() {
		const allBurgerPlaces = await getBurgerPlacesWithId()
		const myBurgersData = await getMyBurgers(currentUser.email)
		const myBurgerPlaces = myBurgersData?.map((burgerData) => burgerData.displayName)
		const availableBurgerPlaces = allBurgerPlaces?.filter(
			(burgerPlace) => !myBurgerPlaces.includes(burgerPlace.displayName)
		)
		setBurgerPlaces(availableBurgerPlaces)
		setLoadingBurgerPlaces(false)
	}

	async function asyncSetMyBurgers() {
		const myTempBurgers = await getMyBurgers(currentUser.email)
		setMyBurgers(
			myTempBurgers.map((place, index, array) =>
				SetRatingFromScore(place, index, array, isSortingScoreBurger, 'score', 'burgerScore')
			)
		)
	}

	async function asyncGetDatabaseName(displayName) {
		const allBurgerPlaces = await getBurgerPlacesWithId()
		const selectedBurgerPlaceName = allBurgerPlaces
			?.filter((burgerPlace) => burgerPlace.displayName === displayName)
			?.map((burgerPlace) => burgerPlace.id)[0]
		return selectedBurgerPlaceName
	}

	useEffect(() => {
		asyncSetMyBurgers()
		asyncSetBurgerPlaces()
		// eslint-disable-next-line
	}, [showNewAssessment, showEdit, showDelete])

	useEffect(() => {
		setMyBurgers(
			myBurgers
				?.sort(isSortingScoreBurger ? (a, b) => b.burgerScore - a.burgerScore : (a, b) => b.score - a.score)
				.map((place, index, array) =>
					SetRatingFromScore(place, index, array, isSortingScoreBurger, 'score', 'burgerScore')
				)
		)
		// eslint-disable-next-line
	}, [isSortingScoreBurger])

	function handleNewAssessmentImageChange(e) {
		setNewAssessmentImage(e.target.files[0])
	}

	function handleEditAssessmentImageChange(e) {
		setEditAssessmentImage(e.target.files[0])
	}

	async function handleSubmitNewAssessment(e) {
		e.preventDefault()

		try {
			setErrorNewAssessment('')
			setLoadingNewAssessment(true)
			var imageUrl = ''
			if (newAssessmentImage) {
				imageUrl = await uploadImageAndGetUrl(newAssessmentImage)
			}
			const newAssessmentName = await asyncGetDatabaseName(displayNameRef.current.value)
			const score =
				parseInt(meatRef.current.value) +
				parseInt(bunRef.current.value) +
				parseInt(extraRef.current.value) +
				parseInt(friesRef.current.value) +
				parseInt(dipRef.current.value)
			const burgerScore =
				parseInt(meatRef.current.value) + parseInt(bunRef.current.value) + parseInt(extraRef.current.value)
			await setNewMyBurger(
				currentUser.email,
				newAssessmentName,
				displayNameRef.current.value,
				parseInt(meatRef.current.value),
				parseInt(bunRef.current.value),
				parseInt(extraRef.current.value),
				parseInt(friesRef.current.value),
				parseInt(dipRef.current.value),
				commentRef.current.value,
				score,
				burgerScore,
				imageUrl
			)
			await updateBurgerPlacePoints(newAssessmentName, score, burgerScore)
			setShowNewAssessment(false)
		} catch {
			setErrorNewAssessment('Noko gjekk galt! Kunne ikkje legga til ny vurdering')
		}
		setLoadingNewAssessment(false)
	}

	async function handleSubmitEdit(e) {
		e.preventDefault()

		try {
			setErrorEdit('')
			setLoadingEdit(true)
			var imageUrl = ''
			if (editAssessmentImage) {
				imageUrl = await uploadImageAndGetUrl(editAssessmentImage)
				if (assessmentToEdit.url) {
					await deleteImage(assessmentToEdit.url)
				}
			} else {
				imageUrl = assessmentToEdit.url
			}
			const editName = await asyncGetDatabaseName(assessmentToEdit.displayName)
			const score =
				assessmentToEdit.meat +
				assessmentToEdit.bun +
				assessmentToEdit.extra +
				assessmentToEdit.fries +
				assessmentToEdit.dip
			const burgerScore = assessmentToEdit.meat + assessmentToEdit.bun + assessmentToEdit.extra
			const oldScore = assessmentToEdit.score
			const oldBurgerScore = assessmentToEdit.burgerScore
			await setNewMyBurger(
				currentUser.email,
				editName,
				assessmentToEdit.displayName,
				assessmentToEdit.meat,
				assessmentToEdit.bun,
				assessmentToEdit.extra,
				assessmentToEdit.fries,
				assessmentToEdit.dip,
				assessmentToEdit.comment,
				score,
				burgerScore,
				imageUrl
			)
			await editBurgerPlacePoints(editName, score, burgerScore, oldScore, oldBurgerScore)
			setShowEdit(false)
		} catch {
			setErrorEdit('Noko gjekk galt! Kunne ikkje redigera vurderinga')
		}
		setLoadingEdit(false)
	}

	async function handleSubmitDelete(e) {
		e.preventDefault()

		try {
			setErrorDelete('')
			setLoadingDelete(true)
			const deleteName = await asyncGetDatabaseName(assessmentToDelete.displayName)
			const oldScore = assessmentToDelete.score
			const oldBurgerScore = assessmentToDelete.burgerScore
			await deleteMyBurger(currentUser.email, deleteName)
			await deleteBurgerPlacePoints(deleteName, oldScore, oldBurgerScore)
			if (assessmentToDelete.url) {
				await deleteImage(assessmentToDelete.url)
			}
			setShowDelete(false)
		} catch {
			setErrorDelete('Noko gjekk galt! Kunne ikkje sletta vurderinga')
		}
		setLoadingDelete(false)
	}

	const handleCloseNewAssessment = () => {
		setShowNewAssessment(false)
		setErrorNewAssessment('')
	}
	const handleShowNewAssessment = () => setShowNewAssessment(true)

	const handleCloseEdit = () => {
		setShowEdit(false)
		setErrorEdit('')
	}
	const handleShowEdit = (assessment) => {
		setAssessmentToEdit(assessment)
		setShowEdit(true)
	}

	const handleCloseDelete = () => {
		setShowDelete(false)
		setErrorDelete('')
	}
	const handleShowDelete = (assessment) => {
		setAssessmentToDelete(assessment)
		setShowDelete(true)
	}

	const handleRangeChange = (fieldName, newValue) => {
		setAssessmentToEdit((prevData) => ({
			...prevData,
			[fieldName]: newValue,
		}))
	}

	const handleCommentChange = (event) => {
		setAssessmentToEdit((prevData) => ({
			...prevData,
			comment: event.target.value,
		}))
	}

	async function handleCreateLink(displayName) {
		const databaseName = await asyncGetDatabaseName(displayName)
		navigate(`/all-assessments/${databaseName}`)
	}

	if (loadingBurgerPlaces) {
		return null
	}

	return (
		<>
			<Header />
			<Container className="d-flex align-items-center justify-content-center">
				<div className="w-100" style={{ maxWidth: '400px' }}>
					<h2 className="text-center mt-5">Mine vurderingar</h2>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							marginLeft: '12px',
							marginTop: '20px',
						}}
					>
						<h5>Sortering: </h5>
						<Form.Check
							className="custom-switch"
							inline
							type="switch"
							label="Berre burger"
							checked={isSortingScoreBurger}
							onChange={(e) => setIsSortingScoreBurger(e.target.checked)}
						/>
					</div>
					<div className="d-grid gap-3 mb-3">
						<Button style={{ backgroundColor: 'rgb(33,33,60)' }} onClick={handleShowNewAssessment}>
							Legg til ny vurdering
						</Button>
					</div>
					<Accordion>
						{myBurgers?.map((burgerPlace, index) => (
							<div key={index} className="mb-1">
								<Accordion.Item eventKey={index}>
									<Accordion.Header>
										{burgerPlace.rating}. {burgerPlace.displayName}
									</Accordion.Header>
									<Accordion.Body>
										<Assessment burgerPlace={burgerPlace} />
										<div className="d-grid gap-2 mt-2 text-center">
											<Button
												style={{ backgroundColor: 'rgb(33,33,60)' }}
												onClick={() => handleShowEdit(burgerPlace)}
											>
												Rediger vurdering
											</Button>
											<Button
												style={{ backgroundColor: 'rgb(33,33,60)' }}
												onClick={() => handleShowDelete(burgerPlace)}
											>
												Slett vurdering
											</Button>
											<Button
												style={{ color: 'rgb(33,33,60)' }}
												variant="link"
												onClick={() => handleCreateLink(burgerPlace.displayName)}
											>
												Sjå alle vurderingar av {burgerPlace.displayName}
											</Button>
										</div>
									</Accordion.Body>
								</Accordion.Item>
							</div>
						))}
					</Accordion>
					<div className="p-3" />
				</div>
			</Container>

			<AddAssessment
				showNewAssessment={showNewAssessment}
				handleCloseNewAssessment={handleCloseNewAssessment}
				displayNameRef={displayNameRef}
				burgerPlaces={burgerPlaces}
				meatRef={meatRef}
				bunRef={bunRef}
				extraRef={extraRef}
				friesRef={friesRef}
				dipRef={dipRef}
				commentRef={commentRef}
				handleNewAssessmentImageChange={handleNewAssessmentImageChange}
				loadingNewAssessment={loadingNewAssessment}
				handleSubmitNewAssessment={handleSubmitNewAssessment}
				errorNewAssessment={errorNewAssessment}
			/>

			<EditAssessment
				showEdit={showEdit}
				handleCloseEdit={handleCloseEdit}
				assessmentToEdit={assessmentToEdit}
				handleRangeChange={handleRangeChange}
				handleCommentChange={handleCommentChange}
				handleEditAssessmentImageChange={handleEditAssessmentImageChange}
				loadingEdit={loadingEdit}
				handleSubmitEdit={handleSubmitEdit}
				errorEdit={errorEdit}
			/>

			<DeleteAssessment
				showDelete={showDelete}
				handleCloseDelete={handleCloseDelete}
				assessmentToDelete={assessmentToDelete}
				loadingDelete={loadingDelete}
				handleSubmitDelete={handleSubmitDelete}
				errorDelete={errorDelete}
			/>
		</>
	)
}
