import React, { useState, useEffect } from 'react'
import { Container, Form } from 'react-bootstrap'
import Header from './Header'
import { getBurgerPlacesWithId } from '../database'
import { useNavigate } from 'react-router-dom'

export default function AllAssessments() {
	const [allBurgerPlaces, setAllBurgerPlaces] = useState([])
	const [loadingAllBurgerPlaces, setLoadingAllBurgerPlaces] = useState(true)
	const navigate = useNavigate()

	async function asyncSetAllBurgerPlaces() {
		setAllBurgerPlaces(await getBurgerPlacesWithId())
		setLoadingAllBurgerPlaces(false)
	}

	useEffect(() => {
		asyncSetAllBurgerPlaces()
	}, [])

	if (loadingAllBurgerPlaces) {
		return null
	}

	return (
		<>
			<Header />
			<Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '75vh' }}>
				<div className="w-100" style={{ maxWidth: '400px' }}>
					<h2 className="text-center my-5">Alle vurderingar</h2>
					<Form.Select className="mb-3" onChange={(e) => navigate(`/all-assessments/${e.target.value}`)}>
						<option>Velg burgerplass</option>
						{allBurgerPlaces
							?.sort((a, b) => a.displayName.localeCompare(b.displayName))
							.filter((b) => b.nAssessments > 0)
							.map((burgerPlace, index) => (
								<option key={index} value={burgerPlace.id}>
									{burgerPlace.displayName}
								</option>
							))}
					</Form.Select>
				</div>
			</Container>
		</>
	)
}
