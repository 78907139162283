import React from 'react'
import Signup from './Signup'
import Menu from './Menu'
import Login from './Login'
import PrivateRoute from './PrivateRoute'
import AdminRoute from './AdminRoute'
import MenuRoute from './MenuRoute'
import ForgotPassword from './ForgotPassword'
import { AuthProvider } from '../contexts/AuthContext'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Rating from './Rating'
import Admin from './Admin'
import MyAssessments from './MyAssessments'
import AllAssessments from './AllAssessments'
import AllAssessmentsForBurgerPlace from './AllAssessmentsForBurgerPlace'
import Frontpage from './Frontpage'

function App() {
	return (
		<Router>
			<AuthProvider>
				<Routes>
					<Route exact path="/" element={<MenuRoute />}>
						<Route exact path="/" element={<Frontpage />} />
					</Route>
					<Route path="/menu" element={<PrivateRoute />}>
						<Route path="/menu" element={<Menu />} />
					</Route>
					<Route path="/signup" element={<Signup />} />
					<Route path="/login" element={<Login />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/rating" element={<PrivateRoute />}>
						<Route path="/rating" element={<Rating />} />
					</Route>
					<Route path="/my-assessments" element={<PrivateRoute />}>
						<Route path="/my-assessments" element={<MyAssessments />} />
					</Route>
					<Route path="/all-assessments" element={<PrivateRoute />}>
						<Route path="/all-assessments" element={<AllAssessments />} />
					</Route>
					<Route path="/all-assessments/:burgerplace" element={<PrivateRoute />}>
						<Route path="/all-assessments/:burgerplace" element={<AllAssessmentsForBurgerPlace />} />
					</Route>
					<Route path="/admin" element={<AdminRoute />}>
						<Route path="/admin" element={<Admin />} />
					</Route>
				</Routes>
			</AuthProvider>
		</Router>
	)
}

export default App
