import React from 'react'
import { Alert, Button, Modal, Form } from 'react-bootstrap'

export default function AddAdmin({
	showAdmin,
	handleCloseAdmin,
	emailRef,
	nonAdmins,
	loadingAdmin,
	handleSubmitAdmin,
	errorAdmin,
}) {
	return (
		<Modal show={showAdmin} onHide={handleCloseAdmin}>
			<Modal.Header closeButton>
				<Modal.Title>Legg til ein ny person som admin</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="adminForm.ControlInput1">
						<Form.Label>Epostadresse</Form.Label>
						<Form.Select custom="true" ref={emailRef}>
							{nonAdmins?.map((user, index) => (
								<option key={index} value={user}>
									{user}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{errorAdmin && <Alert variant="danger">{errorAdmin}</Alert>}
				<Button variant="secondary" onClick={handleCloseAdmin}>
					Avbryt
				</Button>
				<Button
					disabled={loadingAdmin}
					style={{ backgroundColor: 'rgb(33,33,60)' }}
					onClick={handleSubmitAdmin}
				>
					Lagra admin
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
