import React, { useState, useEffect } from 'react'
import { Navbar, Nav, Container, Alert, Button, NavDropdown } from 'react-bootstrap'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import { getAdmins } from '../database'
import AddFeedback from './Modals/Header/AddFeedback'

export default function Header() {
	const [errorLogout, setErrorLogout] = useState('')
	const [admins, setAdmins] = useState()
	const [showNewFeedback, setShowNewFeedback] = useState(false)
	const { logout, currentUser } = useAuth()
	const navigate = useNavigate()

	async function asyncSetAdmins() {
		setAdmins(await getAdmins())
	}

	useEffect(() => {
		asyncSetAdmins()
	}, [])

	async function handleLogout() {
		setErrorLogout('')
		try {
			await logout()
			navigate('/')
		} catch {
			setErrorLogout('Kunne ikkje logga ut')
		}
	}

	return (
		<>
			<Navbar sticky="top" style={{ backgroundColor: 'rgb(33,33,60)' }}>
				<Container>
					<Navbar.Brand href="/menu">
						<img
							src={`${process.env.PUBLIC_URL}/images/logo.png`}
							width="50"
							alt="Logo for Burgervurdering"
						/>
					</Navbar.Brand>
					{admins?.includes(currentUser.email) && (
						<Nav className="me-auto">
							<Nav.Link href="/admin" style={{ color: 'rgb(255,237,0)' }}>
								Admin
							</Nav.Link>
						</Nav>
					)}
					<Navbar.Toggle />
					<Navbar.Collapse className="justify-content-end">
						<NavDropdown title="Profil" align="end" style={{ color: 'rgb(255,237,0)' }}>
							<NavDropdown.Item>
								Logga inn som: <br /> <strong>{currentUser.email}</strong>
							</NavDropdown.Item>
							<NavDropdown.Item>
								<Button style={{ color: 'rgb(255,237,0)' }} variant="link" onClick={handleLogout}>
									Logg ut
								</Button>
							</NavDropdown.Item>
							<NavDropdown.Item>
								<Button
									style={{ backgroundColor: 'rgb(33,33,60)' }}
									onClick={() => setShowNewFeedback(true)}
								>
									Send inn tilbakemelding
								</Button>
							</NavDropdown.Item>
						</NavDropdown>
					</Navbar.Collapse>
				</Container>
			</Navbar>
			{errorLogout && <Alert variant="danger">{errorLogout}</Alert>}

			<AddFeedback
				showNewFeedback={showNewFeedback}
				setShowNewFeedback={setShowNewFeedback}
				email={currentUser.email}
			/>
		</>
	)
}
