import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

export default function Frontpage() {
	const navigate = useNavigate()

	return (
		<div style={{ backgroundColor: 'rgb(33,33,60)', height: '100vh' }}>
			<Container className="d-flex align-items-center justify-content-center" style={{ minHeight: '75vh' }}>
				<div
					className="w-100 d-flex flex-column align-items-center justify-content-center"
					style={{ maxWidth: '400px' }}
				>
					<img src={`${process.env.PUBLIC_URL}/images/logo.png`} width="250" alt="Logo for Burgervurdering" />
					<h1 style={{ color: 'rgb(255,237,0)', marginBottom: '2rem' }}>Burgervurdering</h1>
					<div className="d-grid gap-3">
						<Button
							variant="outline-secondary"
							style={{ borderColor: 'rgb(255,237,0)', color: 'rgb(255,237,0)' }}
							onClick={() => navigate('/login')}
						>
							Logg inn
						</Button>
						<Button
							variant="outline-secondary"
							style={{ borderColor: 'rgb(255,237,0)', color: 'rgb(255,237,0)' }}
							onClick={() => navigate('/signup')}
						>
							Lag ny brukar
						</Button>
					</div>
				</div>
			</Container>
		</div>
	)
}
