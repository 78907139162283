import React from 'react'
import { Table } from 'react-bootstrap'

export default function Assessment({ burgerPlace }) {
	return (
		<>
			<h4 className="text-center">Poeng</h4>
			<Table striped bordered hover>
				<thead>
					<tr>
						<th>Kategori</th>
						<th>Poeng</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Kjøtt/
							<br />
							vegetaralternativ
						</td>
						<td>{burgerPlace.meat}</td>
					</tr>
					<tr>
						<td>Brød</td>
						<td>{burgerPlace.bun}</td>
					</tr>
					<tr>
						<td>Tilbehør</td>
						<td>{burgerPlace.extra}</td>
					</tr>
					<tr>
						<td style={{ fontWeight: 'bold' }}>Burgerpoeng</td>
						<td style={{ fontWeight: 'bold' }}>{burgerPlace.burgerScore}</td>
					</tr>
					<tr>
						<td>Pommes</td>
						<td>{burgerPlace.fries}</td>
					</tr>
					<tr>
						<td>Dip</td>
						<td>{burgerPlace.dip}</td>
					</tr>
					<tr>
						<td style={{ fontWeight: 'bold' }}>Totalt</td>
						<td style={{ fontWeight: 'bold' }}>{burgerPlace.score}</td>
					</tr>
				</tbody>
			</Table>
			{burgerPlace.comment !== '' && <h4 className="text-center">Kommentar</h4>}
			{burgerPlace.comment !== '' && <div style={{ whiteSpace: 'pre-wrap' }}>{burgerPlace.comment}</div>}
			{burgerPlace.url && burgerPlace.url !== '' && <h4 className="text-center">Bilete</h4>}
			{burgerPlace.url && burgerPlace.url !== '' && (
				<img alt="bilete av burger" style={{ maxWidth: '100%', height: 'auto' }} src={burgerPlace.url}></img>
			)}
		</>
	)
}
