import React from 'react'
import { Alert, Button, Modal, Form } from 'react-bootstrap'

export default function DeleteAssessment({
	showDelete,
	handleCloseDelete,
	assessmentToDelete,
	loadingDelete,
	handleSubmitDelete,
	errorDelete,
}) {
	return (
		<Modal show={showDelete} onHide={handleCloseDelete}>
			<Modal.Header closeButton>
				<Modal.Title>Slett vurdering</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group className="mb-3" controlId="deleteForm.ControlInput1">
						<Form.Label>
							Er du sikker på at du vil sletta vurderinga di av{' '}
							<strong>{assessmentToDelete?.displayName}</strong>?
						</Form.Label>
					</Form.Group>
				</Form>
			</Modal.Body>
			<Modal.Footer>
				{errorDelete && <Alert variant="danger">{errorDelete}</Alert>}
				<Button variant="secondary" onClick={handleCloseDelete}>
					Avbryt
				</Button>
				<Button
					disabled={loadingDelete}
					style={{ backgroundColor: 'rgb(33,33,60)' }}
					onClick={handleSubmitDelete}
				>
					Slett vurdering
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
