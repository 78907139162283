import React from 'react'
import Burgermeter from './Burgermeter'

export default function BurgerPlaceAndPoints({
	displayName,
	points,
	rating,
	url,
	nAssessments,
	id,
	isSortingScoreBurger,
	index,
	ratingWidth,
}) {
	const cssClass = index % 2 !== 0 ? 'bg-light p-3 mb-3' : 'p-3 mb-3'

	const linkStyle = {
		textDecoration: 'none',
		color: 'inherit',
		cursor: 'pointer',
	}

	if (nAssessments === 0) {
		return null
	}

	return (
		<>
			<div
				className={cssClass}
				style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
			>
				<a href={url} target="_blank" rel="noopener noreferrer" style={linkStyle}>
					<div>
						{rating}. {displayName}
					</div>
				</a>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						width: ratingWidth,
					}}
				>
					<Burgermeter points={points} totalBurgers={isSortingScoreBurger ? 3 : 5} />
					<a href={`all-assessments/${id}`} style={linkStyle}>
						<div>
							{points} ({nAssessments})
						</div>
					</a>
				</div>
			</div>
		</>
	)
}
